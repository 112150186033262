.contact-box-container {
  background-color: rgb(174, 176, 171);
  margin-top: -5px;
  padding: 75px 0 125px 0;
}

.contact-box {
  color: black;
  top: 40%;
  left: 50%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
}

.contact-text {
  font-size: 16px;
}

.social-logo {
  margin-right: 5px;
}

.insta-link {
  color: black;
  text-decoration: none;
}

.insta-link:hover {
  cursor: pointer;
}

/* Small desktop */
@media screen and (max-width: 1080px) and (min-width: 768px) {
  .start-image-text-contact {
    top: 40%;
  }

  .contact-text {
    font-size: 20px;
    color: black;
  }

  .contact-box {
    position: absolute;
    top: 60%;
    width: 40%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  .contact-box-container {
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  }
}

@media screen and (min-width: 1080px) {
  .start-image-text-contact {
    top: 50%;
  }

  .contact-text {
    font-size: 24px;
    color: black;
  }

  .contact-box {
    position: absolute;
    top: 80%;
    width: 40%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  .contact-box-container {
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  }
}
