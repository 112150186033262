.start-image {
  width: 100%;
}

.page-container {
  margin-top: -80px;
}

.start-image-text {
  color: white;
  position: absolute;
  top: 23%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
}

.start-image-text-item {
  margin: 10px;
}

.start-image-header {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: -5px !important;
}

.text-container {
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto;
  align-items: center;
  text-align-last: center;
  text-align: center;
}

.text-header {
  font-size: 26px;
  border-bottom: solid rgb(156, 111, 37) 3px;
  margin: 20px;
}

.text-item {
  width: 90%;
  font-size: 18px;
  margin: 10px;
  text-align: center;
}

.images-container {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.image-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: rgb(174, 176, 171);
  padding: 20px 0;
}

.image-half {
  width: 80%;
  margin: 0.5% auto;
}

.image-full {
  width: 95%;
  margin: -38px auto 0 auto;
}

.boka-btn {
  background-color: rgb(140, 161, 128);
  color: white;
  margin: 20px 0;
  padding: 5px 15px;
  font-size: large;
  border: none;
}

.boka-btn:hover {
  cursor: pointer;
  background-color: rgb(119, 137, 109);
}

.price-container {
  margin-top: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(174, 176, 171);
}

.price-header {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 12px;
}

.price-text {
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-top: 0;
}

.price-row {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.image-package-container {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
}

.image-package {
  padding: 10px;
  border-style: dashed;
  border-color: rgb(174, 176, 171);
  margin: 0 20px;
}

.image-package-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
}

.image-package-header {
  font-size: 26px;
  margin: 0;
}

.package-price {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.price-list {
  font-weight: bold;
  text-align: left;
  text-align-last: auto;
}

.price-list-item {
  text-align: left;
}

/* Small desktop */
@media screen and (max-width: 1080px) and (min-width: 768px) {
  .start-image-text {
    top: 30%;
  }

  .start-image-header {
    font-size: 58px;
    margin-bottom: 0;
  }

  .start-image-text {
    font-size: 24px;
  }

  .start-image-text-item {
    margin: 10px;
  }

  .price-container {
    width: 50%;
  }

  .image-package-container {
    flex-direction: row;
  }
}

/* Desktop */
@media screen and (min-width: 1080px) {
  .start-image-text {
    top: 70%;
  }

  .start-image-header {
    font-size: 72px;
    margin-bottom: 0;
  }

  .start-image-text {
    font-size: 32px;
  }

  .start-image-text-item {
    margin: 10px;
  }

  .text-header {
    font-size: 50px;
  }

  .text-item {
    width: 80%;
    font-size: 24px;
  }

  .image-row {
    flex-direction: row;
  }

  .image-half {
    width: 45%;
    margin: 0.5%;
  }

  .image-full {
    width: 91%;
    margin-top: -30px;
  }

  .price-container {
    width: 50%;
  }

  .image-package-container {
    flex-direction: row;
  }
}
