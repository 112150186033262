.price-header-big-mobile {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .price-header-big-mobile {
    margin-top: 0;
  }

  .price-item {
    text-align: left;
    text-align-last: left;
    margin: 15px;
  }
}
