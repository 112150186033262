.homepage-instagram-container {
  display: flex;
  align-items: center;
}

.carousel-container {
  background-color: rgb(174, 176, 171);
  margin-top: -5px;
  display: flex;
  justify-content: center;
}

.text-container-carousel {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  text-align-last: center;
  background-color: white;
  width: 80%;
  margin: 20px 0;
  border-radius: 30px;
}

.this-is-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.this-is-me-image {
  width: 80%;
}

.uncenter-last {
  text-align-last: auto;
}

.insta-icon {
  size: 32px;
}

/* Small desktop */
@media screen and (max-width: 1080px) and (min-width: 768px) {
  .insta-icon {
    size: 24px;
  }
}

/* Desktop */
@media screen and (min-width: 1080px) {
  .this-is-me-container {
    flex-direction: row;
  }

  .this-is-me-image {
    width: 40%;
  }
}
