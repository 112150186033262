/* All versions */
.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
}

.navbar-logo {
  height: 90px;
  margin: auto;
  margin-left: 10px;
}

.navbar-logo:hover {
  cursor: pointer;
}

.navbar-elements {
  padding-top: 20px;
  display: flex;
  margin-left: auto;
  z-index: 1;
}

.navbar-element {
  display: none;
  text-decoration: none;
  color: white;
}

/* Mobile version */
.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 0.9;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(100%);
}

.modal {
  width: 100%;
  height: 100%;
}

.navbar-menu {
  display: flex;
  margin-right: 20px;
  border: solid black 1px;
  border-radius: 5px;
  padding: 2px;
}

.navbar-menu:hover {
  cursor: pointer;
}

.mobile-navbar-menu {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  z-index: 998;
}

.mobile-navbar-menu-item {
  padding: 5px;
  text-decoration: none;
  color: white;
  font-size: 36px;
  font-weight: bold;
  margin: 15px auto;
  z-index: 999;
}

.mobile-navbar-menu-item:hover {
  cursor: pointer;
  border-bottom: solid rgb(156, 111, 37) 3px;
}

/* Desktop version */
@media screen and (min-width: 1068px) {
  .navbar-element {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    margin: 0 40px;
  }

  .navbar-elements {
    padding-top: 40px;
    display: flex;
    align-items: center;
    margin-left: 0;
    z-index: 1;
  }

  .navbar-last-element {
    border: none;
  }

  .navbar-element:hover {
    cursor: pointer;
    border-bottom: solid rgb(156, 111, 37) 3px;
  }

  .navbar-menu {
    display: none;
  }

  .mobile-navbar-menu-item {
    display: none;
  }
}
