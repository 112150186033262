.price-list-wedding {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: left;
}

.price-list-wedding-item {
  text-align-last: left;
  font-size: 18px;
}

.wedding-price-container {
  width: 80%;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .wedding-price-container {
    width: 60%;
  }
}
