/* Mobile version */
.carousel-root.carousel {
  width: 80%;
}

.carousel-text {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

/* Desktop */
@media screen and (min-width: 1080px) {
  .carousel-root.carousel {
    width: 60%;
  }

  .carousel-text {
    font-size: 24px;
  }
}
